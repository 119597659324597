import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Store from '@/services/Store'
import localizations from '@/config/localizations.json'

Vue.use(VueI18n)

const localeService = class {
  constructor () {
    this.Instance = new VueI18n({
      locale: null,
      fallbackLocale: null
    })
  }

  component () {
    return this.Instance
  }

  set (lang, data) {
    this.Instance.setLocaleMessage(lang, data)
    this.Instance.locale = lang
    if (Store.getters.isMultiLanguage) {
      Store.dispatch('cookie/set', { lang: lang })
    }
    fn.setLocalization(
      localizations[lang] || localizations.system,
      localizations.system
    )
  }

  setFallback (lang) {
    if (!this.Instance.fallbackLocale) {
      this.Instance.fallbackLocale = lang
    }
  }

  detect () {
    var lang = ''
    var langDefault

    // get 1. from url
    var url = fn.currentUri()
    fn.each(Store.state.languages, (language, key) => {
      if (language.default) {
        langDefault = key

      // detects /en, /en/ and /en/home, but not /england
      } else if (
        url.substr(0, key.length + 2) === '/' + key + '/' ||
        url === '/' + key
      ) {
        lang = key
      }
    })

    // get 2. from cookie and 3. from browser-language,
    // but only if no path is given
    if (fn.currentUri() === '/') {
      if (!this.isValid(lang)) {
        lang = Store.getters['cookie/get']('lang')
      }
      if (!this.isValid(lang)) {
        let userLang = navigator.language || navigator.userLanguage
        if (fn.isString(userLang)) {
          lang = fn.lower(userLang.substr(-2))
        }
      }
    }

    // get default
    if (!this.isValid(lang)) {
      lang = langDefault
    }
    return lang
  }

  isValid (lang) {
    return fn.isString(lang) && fn.has(Store.state.languages, lang)
  }
}

export default new localeService()
