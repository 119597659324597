import Vue from 'vue'
import Store from '@/services/Store'

// Async-Syntax
// import(/* webpackChunkName: `lang-${locale}` */ `@/lang/${locale}.json`)

/**
 * All pages must be registered here
 */
const pages = {
  'error': () => import(/* webpackChunkName: `error-page` */ '@/components/pages/Error'),
  'default': () => import(/* webpackChunkName: `default-page` */ '@/components/pages/Default'),
  'home': () => import(/* webpackChunkName: `home-page` */ '@/components/pages/Home'),
  'rooms': () => import(/* webpackChunkName: `roomw-page` */ '@/components/pages/Rooms'),
  'blog': () => import(/* webpackChunkName: `blog-page` */ '@/components/pages/Blog'),
  'article': () => import(/* webpackChunkName: `article-page` */ '@/components/pages/Article'),
  'events': () => import(/* webpackChunkName: `events-page` */ '@/components/pages/Events'),
  'event_course': () => import(/* webpackChunkName: `event-page` */ '@/components/pages/Event'),
  'event_workshop': () => import(/* webpackChunkName: `event-page` */ '@/components/pages/Event'),
  'event_single': () => import(/* webpackChunkName: `event-page` */ '@/components/pages/Event'),
  'request_appartement': () => import(/* webpackChunkName: `request-appartement-page` */ '@/components/pages/RequestAppartement'),
  'request_group': () => import(/* webpackChunkName: `request-group-page` */ '@/components/pages/RequestGroup'),
  'request_event': () => import(/* webpackChunkName: `request-event-page` */ '@/components/pages/RequestEvent')
}

const loaderService = class {

  /**
   * Load all components of folder /elements
   */
  loadGlobalElements () {

    // nor variables here, term must be statically analyzable!!!
    var RequireObject = require.context(
      '../components/base',
      false,
      /[A-Z]\w+\.(vue|js)$/
    )
    RequireObject.keys().forEach((path) => {
      var Component = RequireObject(path)
      Component = (Component.default || Component)
      Vue.component(fn.pascalCase(Component.name), Component)
    })
  }

  /**
   * Load a page
   * @param {string} blueprint, optional
   */
  getPage (blueprint) {
    if (Store.getters.pageError) {
      blueprint = 'error'
    } else if (!fn.isString(blueprint)) {
      blueprint = Store.state.page.head.blueprint
    }
    blueprint = fn.lower(blueprint)
    if (typeof pages[blueprint] !== 'undefined') {
      return pages[blueprint]
    }
    info(blueprint + ' not defined, use default')
    return pages.default
  }
}

export default new loaderService()
