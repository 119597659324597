import Form from '@/services/Form'
import API from '@/services/API'

const requestGroupStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    result: 0, // 0 = init, 1 = sent, 10 = unknown error, 11 = input error
    data: {
      salutation: {
        type: 'select',
        value: null,
        preset: 'female',
        error: false,
        required: true,
        options: [{
          key: 'female',
          label: 'Frau'
        },{
          key: 'male',
          label: 'Herr'
        }]
      },
      prename: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      surname: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      street: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      zip: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      town: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      phone: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      email: {
        type: 'email',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      arrivalDate: {
        type: 'date',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      arrivalTime: {
        type: 'select',
        value: null,
        preset: '16_00',
        error: false,
        required: true,
        options: [
          { key: '14_00', label: '14:00 Uhr' },
          { key: '14_30', label: '14:30 Uhr' },
          { key: '15_00', label: '15:00 Uhr' },
          { key: '15_30', label: '15:30 Uhr' },
          { key: '16_00', label: '16:00 Uhr' },
          { key: '16_30', label: '16:30 Uhr' },
          { key: '17_00', label: '17:00 Uhr' },
          { key: '17_30', label: '17:30 Uhr' },
          { key: '18_00', label: '18:00 Uhr' },
          { key: '18_30', label: '18:30 Uhr' },
          { key: '19_00', label: '19:00 Uhr' },
          { key: '19_30', label: '19:30 Uhr' },
          { key: '20_00', label: '20:00 Uhr' },
          { key: 'nach_20_00', label: 'nach 20:00 Uhr' }
        ]
      },
      departureDate: {
        type: 'date',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      departureTime: {
        type: 'select',
        value: null,
        preset: '9_00',
        error: false,
        required: true,
        options: [
          { key: 'vor_8_00', label: 'vor 8:00 Uhr' },
          { key: '8_00', label: '8:00 Uhr' },
          { key: '8_30', label: '8:30 Uhr' },
          { key: '9_00', label: '9:00 Uhr' },
          { key: '9_30', label: '9:30 Uhr' },
          { key: '10_00', label: '10:00 Uhr' }
        ]
      },
      adultsCount: {
        type: 'integer',
        value: null,
        preset: null,
        min: 1,
        error: false,
        required: true
      },
      teensCount: {
        type: 'integer',
        value: null,
        preset: null,
        min: 0,
        error: false,
        required: false
      },
      childsCount: {
        type: 'integer',
        value: null,
        preset: null,
        min: 0,
        error: false,
        required: false
      },
      catering: {
        type: 'select',
        value: null,
        preset: 'none',
        error: false,
        required: true,
        options: [{
          key: 'none',
          label: 'keine Verpflegung'
        },{
          key: 'self',
          label: 'Selbstverpflegung in der Küche des Hauses'
        },{
          key: 'breakfast',
          label: 'Frühstück'
        },{
          key: 'half',
          label: 'Halbpension'
        },{
          key: 'full',
          label: 'Vollpension'
        }]
      },
      veganCount: {
        type: 'integer',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      message: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      conditions: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: true
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {
    cateringOptions (state, getters, rootState) {
      var res = []
      fn.each(state.data.catering.options, (option) => {
        var keyFull = 'catering' + option.key + 'full'
        var keyRed = 'catering' + option.key + 'red'
        if (rootState.page.calc[keyFull] && rootState.page.calc[keyRed]) {
          let label = option.label + ' [' + 
            fn.currency(rootState.page.calc[keyFull].value) + ', ' + 
            fn.currency(rootState.page.calc[keyRed].value) + '/red.]'
          res.push({
            key: option.key,
            label: label
          })
        } else {
          res.push(option)
        }
      })
      return res
    },
    periodStart () {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
    },
    periodEnd () {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2)
    },
    periodDays (state, getters, rootState) {
      var res = fn.dateDiff(
        state.data.arrivalDate.value,
        state.data.departureDate.value,
        'days'
      )
      if (res > 0 && res <= rootState.page.calc.periodmax.value) {
        return res
      }
      return 0
    },
    periodDaysError (state, getters, rootState) {
      var isDate1 = fn.isDate(state.data.arrivalDate.value)
      var isDate2 = fn.isDate(state.data.departureDate.value)

      // 2 valid arrival date, but too early
      var period = fn.dateDiff(state.data.arrivalDate.value, getters.periodStart, 'days')
      if (isDate1 && period > 0) {
        return 2
      }

      // 1 no valid dates given
      if (!isDate1 || !isDate2) {
        return 1
      }

      // 3 valild dates, but departure before arrival
      period = fn.dateDiff(state.data.arrivalDate.value, state.data.departureDate.value, 'days')
      if (isDate1 && isDate2 && period <= 0) {
        return 3
      }

      // 4 valid dates, but period too long
      period = fn.dateDiff(state.data.arrivalDate.value, state.data.departureDate.value, 'days')
      if (isDate1 && isDate2 && period > rootState.page.calc.periodmax.value) {
        return 4
      }

      // everything OK
      return 0
    },
    adultsCount (state) {
      return fn.isInteger(state.data.adultsCount.value, 1) ? state.data.adultsCount.value : 0
    },
    teensCount (state) {
      return fn.isInteger(state.data.teensCount.value, 1) ? state.data.teensCount.value : 0
    },
    childsCount (state) {
      return fn.isInteger(state.data.childsCount.value, 1) ? state.data.childsCount.value : 0
    },
    personsCount (state, getters) {
      return getters.adultsCount + getters.teensCount
    },
    personsCountError (state, getters, rootState) {

      // max. persons count exceeded
      if (getters.personsCount > rootState.page.calc.personsmax.value) {
        return 1
      }

      // max. childs count exceeded
      if (getters.childsCount > rootState.page.calc.childsmax.value) {
        return 2
      }
      return 0
    },
    calculation (state, getters, rootState) {
      if (getters.periodDaysError || getters.personsCountError) {
        return false
      }
      var periodDays = getters.periodDays
      var adultsCount = getters.adultsCount
      var teensCount = getters.teensCount
      var personsCount = adultsCount + teensCount
      var cateringDiscount = 0
      if (periodDays === 0 || (adultsCount + teensCount) === 0) {
        return false
      }

      // base price including x persons
      var res = {
        base: {
          count: 1,
          single: rootState.page.calc.baseprice.value,
          total: 0,
          including: rootState.page.calc.baseincluded.value
        }
      }

      // surcharge for additional persons, first case: more adults
      if (adultsCount > rootState.page.calc.baseincluded.value) {
        res.additionalAdults = {
          count: adultsCount - rootState.page.calc.baseincluded.value,
          single: rootState.page.calc.adultprice.value,
          total: 0
        }
        if (teensCount > 0) {
          res.additionalTeens = {
            count: teensCount,
            single: rootState.page.calc.teenprice.value,
            total: 0
          }
        }
      }
      
      // second case: less adults, but more teens
      else if ((adultsCount + teensCount) > rootState.page.calc.baseincluded.value) {
        res.additionalTeens = {
          count: (adultsCount + teensCount) - rootState.page.calc.baseincluded.value,
          single: rootState.page.calc.teenprice.value,
          total: 0
        }
      }

      // catering
      switch (state.data.catering.value) {

        // breakfast
        case 'breakfast':
          if (adultsCount > 0) {

            // adults
            res.cateringFull = {
              count: adultsCount,
              single: rootState.page.calc.cateringbreakfastfull.value,
              total: 0,
              catering: 'breakfast'
            }
          }

          // teens
          if (teensCount > 0) {
            res.cateringRed = {
              count: teensCount,
              single: rootState.page.calc.cateringbreakfastred.value,
              total: 0,
              catering: 'breakfast'
            }
          }
          break
        
        // half
        case 'half':
          if (adultsCount > 0) {

            // adults
            res.cateringFull = {
              count: adultsCount,
              single: rootState.page.calc.cateringhalffull.value,
              total: 0,
              catering: 'half'
            }
          }

          // teens
          if (teensCount > 0) {
            res.cateringRed = {
              count: teensCount,
              single: rootState.page.calc.cateringhalfred.value,
              total: 0,
              catering: 'half'
            }
          }
          break

        // full
        case 'full':
          if (adultsCount > 0) {

            // adults
            res.cateringFull = {
              count: adultsCount,
              single: rootState.page.calc.cateringfullfull.value,
              total: 0,
              catering: 'full'
            }
          }

          // teens
          if (teensCount > 0) {
            res.cateringRed = {
              count: teensCount,
              single: rootState.page.calc.cateringfullred.value,
              total: 0,
              catering: 'full'
            }
          }

          // discount
          if (adultsCount > 0) {
            cateringDiscount += (rootState.page.calc.cateringfullfull.value - rootState.page.calc.cateringhalffull.value) * adultsCount
          }
          if (teensCount > 0) {
            cateringDiscount += (rootState.page.calc.cateringfullred.value - rootState.page.calc.cateringhalfred.value) * teensCount
          }
          break
      }

      // calculate each position and a single day
      var single = 0
      fn.each(res, (position) => {
        position.total = position.count * position.single
        single += position.total
      })

      // Discount
      if (cateringDiscount > 0) {
        res.cateringDiscount = {
          count: 1,
          single: - cateringDiscount,
          total: - cateringDiscount
        }
      }

      // add total
      res.sum = {
        count: periodDays,
        single: single,
        total: (periodDays * single) - cateringDiscount,
        average: single / personsCount
      }
      return res
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {
    initForm (state) {
      state.result = 0
      fn.each(state.data, (field) => {
        if (!field.value) {
          field.value = field.preset
        }
        field.error = false
      })
    },
    noInputError (state) {
      state.result = 0
    },
    formSent (state) {
      state.result = 1
      fn.each(state.data, (field) => {
        field.value = field.preset
        field.error = false
      })
    },
    unknownError (state) {
      state.result = 10
    },
    inputError (state) {
      state.result = 11
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {
    validate ({ state, getters, commit }) {
      var error = false
      fn.each(state.data, (field, name) => {
        let valid = Form.typeCheck(field)
        field.error = !valid
        if (field.error) {
          error = true
        }
      })
      switch(getters.periodDaysError) {
        case 1:
          // empty, error already set by typeCheck()
          error = true
          break;
        case 2:
          state.data.arrivalDate.error = true
          error = true
          break;
        case 3:
        case 4:
          state.data.departureDate.error = true
          error = true
          break;
      }
      if (error) {
        commit('inputError')
      } else {
        commit('noInputError')
      }
    },
    submit ({ state, commit, getters, dispatch }) {
      dispatch('validate')
      if (state.result >= 10) {
        return
      }
      var data = {
        __template__: 'request-group',
        __from__: state.data.email.value,
        __date__: fn.dateToString(new Date()),
        __time__: fn.timeToString(new Date())
      }
      fn.each(state.data, (field, name) => {
        data[name] = Form.postValue(field, true)
      })
      fn.each(getters.calculation, (field, name) => {
        data[name] = fn.currency(field.total)
      })
      return API.submit('email', data)
        .then(() => {
          commit('formSent')
        })
        .catch((reason) => {
          commit('unknownError')
        })
    }
  }
}

export default requestGroupStore