import Vue from 'vue'
import Store from '@/services/Store'

const Handler = new Vue()

/**
 * Custom events, use in Vue component:
 * 
 *   this.$trigger('event-name', params)
 *   events: {
 *     'event-name-to-listen-on': 'name-of-function-to-call'
 *   }
 * 
 * Build-in events:
 * 
 *   window/window-width
 *   window/breakpoint-change
 *   window/scroll
 *   window/scroll-down
 *   window/scroll-upd
 */
const EventManager = class {
  constructor () {
    this.windowEvents = true
    this.isTouchDevice = false
  }
  install (Vue, options) {

    // convert breakpoints from object to array
    options.breakpoints = Object.entries(options.breakpoints)

    // set properties
    this.options = options
    this.breakpoint = this.getBreakpoint()
    this.windowWidth = window.innerWidth
    this.scrollY = window.scrollY

    // detect touch
    window.addEventListener('touchstart', () => {
      this.isTouchDevice = true
    }, {
      capture: true,
      once: true
    });

    // register global window events
    this.registerWindowEvents()

    // this.$trigger()
    Vue.prototype.$trigger = (name, params) => {
      var Event = {
        name: name,
        emitter: null,
        params: params
      }
      if (this instanceof Vue && this.$options.name) {
        Event.emitter = this.$options.name
      } else if (name.substr(0, 6) === 'window') {
        Event.emitter = 'window'
      }
      Handler.$emit(name, Event, params)
    }

    // this.$windowEvents()
    Vue.prototype.$windowEvents = (activate) => {
      this.windowEvents = activate ? true : false
    }

    // this.$isTouch()
    Vue.prototype.$isTouch = () => {
      return this.isTouchDevice
    }

    Vue.mixin({
      beforeCreate: initComponentsEvents
    })
  }

  $trigger (name, params) {
    Handler.$trigger(name, params)
  }

  $windowEvents (activate) {
    Handler.$windowEvents(activate)
  }

  $isTouch () {
    return Handler.isTouchDevice()
  }

  /**
   * Build-in window events
   * can be deactivated by this.$windowEvents(false)
   */
  registerWindowEvents () {
    window.addEventListener("resize", () => {
      let breakpoint = this.getBreakpoint()
      if (this.breakpoint !== breakpoint) {
        this.breakpoint = breakpoint
        this.triggerWindowEvent('window/breakpoint-change', this.breakpoint)
      }
      if (this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth
        this.triggerWindowEvent('window/window-width', this.windowWidth)
      }
    }, {
      capture: true
    })
    window.addEventListener('scroll', () => {
      var params = {
        top: window.scrollY,
        bottom: window.scrollY + window.innerHeight,
        diff: window.scrollY - this.scrollY,
        scrolled: window.scrollY > 0
      }
      if (window.scrollY > this.scrollY) {
        params.direction = 'down'
        this.triggerWindowEvent('window/scroll', params)
        this.triggerWindowEvent('window/scroll-down', params)
      } else if (window.scrollY < this.scrollY) {
        params.direction = 'up'
        this.triggerWindowEvent('window/scroll', params)
        this.triggerWindowEvent('window/scroll-up', params)
      }
      this.scrollY = window.scrollY
    }, {
      capture: true
    })
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  triggerWindowEvent (name, params) {
    if (this.windowEvents) {
      this.$trigger(name, params)
    }
  }

  getBreakpoint () {
    var res
    for (var [name, value] of this.options.breakpoints) {
      if (window.innerWidth <= value) {
        res = name
      }
    }
    return res
  }
}

/*
|--------------------------------------------------------------------------
| Get events node from component and register the events
|--------------------------------------------------------------------------
*/

function initComponentsEvents () {
  var self = this
  var _events = []
  var events = self.$options.events
  if (events) {
    fn.each(events, (listener, event) => {
      _events.push(Handler.$on(event, bindListener(listener, self)))
    });
  }
}

function bindListener(callback, vm) {
  if (fn.isString(callback)) {
    return function () {
      return vm[callback].apply(vm, arguments)
   }
  }
  return callback.bind(vm);
}

export default new EventManager()