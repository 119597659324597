import Locale from '@/services/Locale'
import API from '@/services/API'

const RootStore = {

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */

  state: {
    api: null,
    lang: null,
    pagekey: null,
    status: {
      site: null,
      page: null
    },
    languages: {},
    navigation: {},
    site: {},
    page: {},
    elements: {}
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters : {
    currentLanguage: (state) => (property) => {
      let language = state.languages[state.lang]
      return property ? language[property] : language
    },
    defaultLanguage: (state) => {
      var lang
      fn.each(state.languages, (language, key) => {
        if (language.default) {
          lang = key
        }
      })
      return lang
    },
    isMultiLanguage: (state) => {
      return Object.keys(state.languages).length > 1
    },

    siteError: (state) => {
      return state.status.site != 200
    },
    pageError: (state) => {
      return state.status.page != 200 || state.status.site != 200
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  |
  | $store.commit('mutationName')
  |
  */

  mutations: {
    setEnviromentVars (state) {
      state.api = process.env.VUE_APP_API
      info(fn.upper(process.env.NODE_ENV) + ' enviroment')
    },
    setLanguage (state, lang) {
      state.lang = lang
    },
    setLanguages (state, response) {
      state.languages = response.data
    },
    setSiteData (state, response) {
      state.status.site = parseInt(response.status)
      state.site = {}
      fn.each(response.data, (values, key) => {
        if (key === 'navigation') {
          state.navigation = values
        } else {
          state.site[key] = values
        }
      })
      state.site = response.data
    },
    setPageData (state, response) {
      state.status.page = parseInt(response.status)
      state.pagekey = response.pagekey
      if (fn.has(response, 'data')) {
        state.page = response.data
      } else {
        state.page = {}
      }
    },
    registerElement (state, param) {
      state.elements[param.name] = param.elem
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  |
  | $store.dispatch('actionName')
  |
  */

  actions: {

    /**
     * Load information about all available languages and set the fallback
     * language in VueI18n plugin.
     */
    loadLanguages: ({ commit }) => {
      return API.languages()
      .then((response) => {
        commit('setLanguages', response)
        fn.each(response.data, (language, lang) => {
          if (language.default) {
            Locale.setFallback(lang)
          }
        })
      })
      .catch((reason) => {
        return Promise.reject(reason)
      })
    },

    /**
     * used for language switch
     */
    loadLanguage: ({ commit, dispatch, state }, lang) => {
      if (!Locale.isValid(lang) || lang === state.lang) {
        commit()
        return
      }
      return dispatch('loadSite', lang)
    },

    /**
     * Load navigation, site-meta etc. for a specific language.
     * This method also detects and sets the language, because a change of
     * the language always goes together with a reload of site data.
     */
    loadSite: ({ commit }, lang) => {
      if (!Locale.isValid(lang)) {
        lang = Locale.detect()
      }
      if (!Locale.isValid(lang)) {
        return Promise.reject({
          msg: 'Fatal error: no valid language detected'
        })
      }
      return Promise.all([
        API.site(lang),
        API.translations(lang)
      ])
      .then(([ dataSite, dataTranslations ]) => {
        commit('setLanguage', lang)
        commit('setSiteData', dataSite)
        Locale.set(lang, dataTranslations.data)
        info('loaded language and site ' + lang)
      })
      .catch((reason) => {
        return Promise.reject(reason)
      })
    }
  }
}

export default RootStore