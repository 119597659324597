const scrollService = class {

  /**
   * Smooth scrolling to an element or position
   * (native window.scrollTo with option 'smooth' is not working on IE and safari)
   * 
   * target can be:
   * 
   *  - the id of the target element without #
   *  - the element itself, either from document.getElementById() or an object from Vue.$refs
   *  - a number = the scroll to position
   * 
   * @param {mixed} target
   * @param {object} options, { duration, callback, context, offset}
   */
  smooth (target, options) {
    var el, start, end, clock, requestAnimationFrame, step
    options = Object.assign({
      duration: 500,
      callback: null,
      context: window,
      offset: 0
    }, options)
    start = options.context.scrollTop || window.pageYOffset

    // check type of given element
    if (fn.isInteger(target)) {
      end = parseInt(target)
    } else if (fn.isString(target)) {
      el = document.getElementById(target)
      end = this._scrollGetTop(el, start)
    } else if (fn.isObject(target)) {
      end = this._scrollGetTop(target, start)
    } else {
      end = 0 // scroll to top
    }
    if (fn.isInteger(options.offset)) {
      end += options.offset
    }
    clock = Date.now()
    requestAnimationFrame = window.requestAnimationFrame || window.mozRequestAnimationFrame || window.webkitRequestAnimationFrame
    if (!requestAnimationFrame) {
      requestAnimationFrame = (fn) => {
        window.setTimeout(fn, 15)
      }
    }

    step = () => {
      var elapsed
      elapsed = Date.now() - clock
      if (options.context !== window) {
        options.context.scrollTop = this._scrollPosition(start, end, elapsed, options.duration)
      } else {
        window.scroll(0, this._scrollPosition(start, end, elapsed, options.duration))
      }
      if (elapsed > options.duration) {
        if (fn.isFunction(options.callback)) {
          options.callback(el)
        }
      } else {
        requestAnimationFrame(step)
      }
    }
    step()
  }

  /**
   * Native scrollTo function
   * @param {Number} top, top position of the window
   * @param {object} options, { callback, offset }
   */
  native(top, options) {
    if (fn.isInteger(options.offset)) {
      top += options.offset
    }
    const onScrollCb = function () {
      const scrollTop = window.scrollTop || window.pageYOffset
      if (scrollTop === top) {
        window.removeEventListener('scroll', onScrollCb)
        if (fn.isFunction(options.callback)) {
          options.callback()
        }
      }
    }
    window.addEventListener('scroll', onScrollCb)
    onScrollCb()
    window.scrollTo({
      top: top
    })
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  _scrollPosition (start, end, elapsed, duration) {
    if (elapsed > duration) {
      return end
    }
    return start + (end - start) * this._scrollEaseInOutCubic(elapsed / duration)
  }

  _scrollGetTop (element, start) {
    if (element.nodeName === 'HTML') {
      return -start
    }
    return element.getBoundingClientRect().top + start
  }

  _scrollEaseInOutCubic (t) {
    return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
  }
}

export default new scrollService()