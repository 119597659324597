const mapboxStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    apiKey: 'pk.eyJ1IjoiY2FrZXNob3AiLCJhIjoiY2sxMjVqZm1tMDllczNjbWp6ZTFmeno2aiJ9.UaDMZUttlpqDZNuYXXQxlQ',
    long: 9.475955,
    lat: 52.206567,
    style: 'mapbox://styles/mapbox/streets-v11',
    zoom: 8,
    locale: 'de'
  }
}

export default mapboxStore