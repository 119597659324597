import Store from '@/services/Store'

const Matomo = class {
  constructor () {
    this.options = null
  }

  /*
  |--------------------------------------------------------------------------
  | Register
  |--------------------------------------------------------------------------
  */

  /**
   * @param {object} options, { url: foo, id: bar }
   */
  install (Vue, options) {
    if (process.env.NODE_ENV === 'development') {
      return
    }
    if (
      fn.has(options, 'url') && fn.isString(options.url) &&
      fn.has(options, 'id') && fn.isString(options.id)
    ) {
      this.options = options
    } else {
      return
    }

    // registeres as $analytics, so it can be replaced by any other service
    Vue.prototype.$analytics = {
      setConsent: (path) => {
        return this.setConsent(path)
      },
      track: (path, referrer) => {
        return this.track(path, referrer)
      }
    }

    window._paq = []

    /* global _paq */
    _paq.push(['requireConsent'])
    _paq.push(['trackPageView'])
    _paq.push(['enableLinkTracking'])
    _paq.push(['setTrackerUrl', fn.toUrl(this.options.url, 'matomo.php')])
    _paq.push(['setSiteId', this.options.id])

    var d = document
    var g = d.createElement('script')
    var s = d.getElementsByTagName('script')[0]
    g.type = 'text/javascript'
    g.async = true
    g.defer = true
    g.src = fn.toUrl(this.options.url, 'matomo.js')
    s.parentNode.insertBefore(g, s)

    Store.dispatch('cookie/init', { analytics: false })
  }
  
  /*
  |--------------------------------------------------------------------------
  | Interface
  |--------------------------------------------------------------------------
  */

  /**
   * allow tracking and optionally track current route
   * @param {string} path, optional
   */
  setConsent (path) {
    Store.dispatch('cookie/set', { analytics: true })
    if (path) {
      this.track(path)
    }
  }

  /**
   */
  track (path, referrer) {
    if (!this.options || !Store.getters['cookie/get']('analytics')) {
      return
    }
    // eslint-disable-next-line
    _paq.push(['setConsentGiven'])
    _paq.push(['setCustomUrl', path])
    if (referrer) {
      _paq.push(['setReferrerUrl', referrer])
    }
    _paq.push(['setGenerationTimeMs', 0])
    _paq.push(['trackPageView'])
  }
}

export default new Matomo()