import Vue from 'vue'
import Router from 'vue-router'
import Store from '@/services/Store'
import API from '@/services/API'
import DOM from '@/services/DOM'
import Scroll from '@/services/Scroll'
import Analytics from '@/plugins/Matomo'
import DefaultTemplate from '@/components/templates/Default.vue'

Vue.use(Router)

const routerService = class {
  constructor () {
    this.Instance = null
  }

  /*
  |--------------------------------------------------------------------------
  | Router and routes
  |--------------------------------------------------------------------------
  */

  /**
   * Create router
   * @return router
   */
  component () {
    this.Instance = new Router({
      mode: 'history',
      linkActiveClass: '',
      linkExactActiveClass: '',
      routes: [
        {
          path: '/',
          component: DefaultTemplate,
          redirect: this._getHomeUri(),
          children: [
            {
              // just to make incomplete routes with only lang work
              // (doesn't help for default langauges with no slug, which is correct!)
              path: this._getLangSlug(false),
              redirect: this._getHomeUri()
            },
            {
              path: this._getLangSlug(true) + this._getHomeSlug()
            },
            {
              path: this._getLangSlug(true) + '/:slug(.+)'
            }
          ]
        }
      ]
    })

    this.Instance.beforeEach((to, from, next) => {
      if (Store.getters.siteError) {
        next()
        Scroll.smooth()
        return
      }
      info('change route ' + to.fullPath)
      API.node(to.params.slug)
        .then((response) => {
          response.pagekey = to.fullPath
          Store.commit('setPageData', response)
          DOM.setPageMeta()
          next()
          Scroll.smooth()
          Analytics.track(to.fullPath, from.fullPath)
        })
        .catch((response) => {
          if (!fn.has(response, 'status')) {
            response.status = 1000
          }
          response.pagekey = to.fullPath
          Store.commit('setPageData', response)
          next()
          error(response.status, response.msg)
          Scroll.smooth()
          Analytics.track(to.fullPath, from.fullPath)
        })
    })

    this.Instance.home = () => {
      return this._getHomeUri()
    }

    return this.Instance
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  /**
   * 
   * @param {bool} optional, add optional quantifier (?), if a language
   * without a slug exists
   */
  _getLangSlug (optional) {
    let reg = []
    let quantifier = ''
    fn.each(Store.state.languages, (language) => {
      if (language.slug) {
        reg.push(language.slug)
      } else if (optional) {
        quantifier = '?'
      }
    })
    if (reg.length > 0) {
      return '/:lang(' + reg.join('|') + ')' + quantifier
    } else {
      return ''
    }
  }

  _getHomeSlug () {
    if (Store.getters.siteError) {
      return '/:slug(error)'
    }
    let reg = []
    fn.each(Store.state.languages, (language) => {
      reg.push(language.homeslug)
    })
    reg = fn.unique(reg)
    return '/:slug(' + reg.join('|') + ')'
  }

  _getHomeUri () {
    return fn.toUri(
      Store.getters.currentLanguage('slug'),
      Store.getters.currentLanguage('homeslug')
    )
  }
}

export default new routerService()
