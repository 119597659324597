import Vue from 'vue'
import Store from '@/services/Store'

const validationService = class {

  /*
  |--------------------------------------------------------------------------
  | Type validations
  |--------------------------------------------------------------------------
  */
  typeCheck (field) {
    switch (field.type) {
      case 'text':
        return this.text(field.value, field.required, field.length)
      case 'integer':
        return this.integer(field.value, field.required, field.min, field.max)
      case 'date':
        return this.date(field.value, field.required)
      case 'time':
        return this.time(field.value, field.required)
      case 'email':
        return this.email(field.value, field.required)
      case 'select':
        return this.select(field.value, field.options, field.required)
      case 'bool':
        return this.bool(field.value, field.required)
    }
  }

  // value can be number or string
  text (val, required, length) {
    if (!required && (val === '' || val === null)) {
      return true
    }
    if (!fn.isInteger(length, 1)) {
      length = 1
    }
    return fn.isString(val, length)
  }

  integer (val, required, min, max) {
    if (!required && val === null) {
      return true
    }
    return fn.isInteger(val, min, max)
  }

  float (val, required, min, max) {
    if (!required && val === null) {
      return true
    }
    return fn.isFloat(val, min, max)
  }

  // val is date object
  date (val, required) {
    if (!required && (val === '' || val === null)) {
      return true
    }
    return fn.isDate(val)
  }

  // intern time format is always hh:ii:ss
  time (val, required) {
    if (!required && (val === '' || val === null)) {
      return true
    }
    return fn.isDate(val)
  }

  // value must be string
  email (val, required) {
    if (!required && (val === '' || val === null)) {
      return true
    }
    return fn.isEmail(val)
  }

  // value must be string
  select (val, options, required) {
    if (!required && (val === '' || val === null)) {
      return true
    }
    var res = false
    fn.each(options, (option) => {
      if (option.key === val) {
        res = true
      }
    })
    return res
  }

  bool (val, required) {
    if (!required) {
      return true
    }
    return val
  }

  /*
  |--------------------------------------------------------------------------
  | Convertation
  |--------------------------------------------------------------------------
  */
  postValue (field, userFormat) {
    switch (field.type) {
      case 'text':
        return field.value
      case 'integer':
        return field.value
      case 'date':
        return fn.isDate(field.value) ? fn.dateToString(field.value, userFormat) : null
      case 'time':
        return fn.isDate(field.value) ? fn.timeToString(field.value, userFormat) : null
      case 'email':
        return field.value
      case 'select':
        return this.selectLabel(field.value, field.options)
      case 'bool':
        return field.value ? 1 : 0
    }
  }

  selectLabel (val, options) {
    var res = val
    fn.each(options, (option) => {
      if (option.key === val) {
        res = option.label
      }
    })
    return res
  }
}

export default new validationService()
