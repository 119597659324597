import Form from '@/services/Form'
import API from '@/services/API'

const requestAppartementStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    result: 0, // 0 = init, 1 = sent, 10 = unknown error, 11 = input error
    data: {
      salutation: {
        type: 'select',
        value: null,
        preset: 'female',
        error: false,
        required: true,
        options: [{
          key: 'female',
          label: 'Frau'
        },{
          key: 'male',
          label: 'Herr'
        }]
      },
      prename: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      surname: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      street: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      zip: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      town: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      phone: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      email: {
        type: 'email',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      arrivalDate: {
        type: 'date',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      arrivalTime: {
        type: 'select',
        value: null,
        preset: '16_00',
        error: false,
        required: true,
        options: [
          { key: '14_00', label: '14:00 Uhr' },
          { key: '14_30', label: '14:30 Uhr' },
          { key: '15_00', label: '15:00 Uhr' },
          { key: '15_30', label: '15:30 Uhr' },
          { key: '16_00', label: '16:00 Uhr' },
          { key: '16_30', label: '16:30 Uhr' },
          { key: '17_00', label: '17:00 Uhr' },
          { key: '17_30', label: '17:30 Uhr' },
          { key: '18_00', label: '18:00 Uhr' },
          { key: '18_30', label: '18:30 Uhr' },
          { key: '19_00', label: '19:00 Uhr' },
          { key: '19_30', label: '19:30 Uhr' },
          { key: '20_00', label: '20:00 Uhr' },
          { key: 'nach_20_00', label: 'nach 20:00 Uhr' }
        ]
      },
      departureDate: {
        type: 'date',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      departureTime: {
        type: 'select',
        value: null,
        preset: '9_00',
        error: false,
        required: true,
        options: [
          { key: 'vor_8_00', label: 'vor 8:00 Uhr' },
          { key: '8_00', label: '8:00 Uhr' },
          { key: '8_30', label: '8:30 Uhr' },
          { key: '9_00', label: '9:00 Uhr' },
          { key: '9_30', label: '9:30 Uhr' },
          { key: '10_00', label: '10:00 Uhr' }
        ]
      },
      personsCount: {
        type: 'integer',
        value: null,
        preset: null,
        min: 1,
        error: false,
        required: true
      },
      appartement: {
        type: 'select',
        value: null,
        preset: 0,
        error: false,
        required: true,
        options: []
      },
      message: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      conditions: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: true
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {
    periodStart () {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1)
    },
    periodEnd () {
      let now = new Date()
      return new Date(now.getFullYear(), now.getMonth(), now.getDate() + 2)
    },
    periodDays (state, getters, rootState) {
      var res = fn.dateDiff(
        state.data.arrivalDate.value,
        state.data.departureDate.value,
        'days'
      )
      if (res > 0 && res <= rootState.page.calc.periodmax.value) {
        return res
      }
      return 0
    },
    periodDaysError (state, getters, rootState) {
      var isDate1 = fn.isDate(state.data.arrivalDate.value)
      var isDate2 = fn.isDate(state.data.departureDate.value)

      // 2 valid arrival date, but too early
      var period = fn.dateDiff(state.data.arrivalDate.value, getters.periodStart, 'days')
      if (isDate1 && period > 0) {
        return 2
      }

      // 1 no valid dates given
      if (!isDate1 || !isDate2) {
        return 1
      }

      // 3 valild dates, but departure before arrival
      period = fn.dateDiff(state.data.arrivalDate.value, state.data.departureDate.value, 'days')
      if (isDate1 && isDate2 && period <= 0) {
        return 3
      }

      // 4 valid dates, but period too long
      period = fn.dateDiff(state.data.arrivalDate.value, state.data.departureDate.value, 'days')
      if (isDate1 && isDate2 && period > rootState.page.calc.periodmax.value) {
        return 4
      }

      // everything OK
      return 0
    },
    personsMax (state) {
      return state.data.appartement.options[state.data.appartement.value].personsmax
    },
    personsCount (state) {
      return fn.isInteger(state.data.personsCount.value, 1) ? state.data.personsCount.value : 0
    },
    personsCountError (state, getters, rootState) {

      // max. persons count exceeded
      if (getters.personsCount > getters.personsMax) {
        return 1
      }
      return 0
    },
    basePrice (state) {
      return state.data.appartement.options[state.data.appartement.value].baseprice
    },
    calculation (state, getters, rootState) {
      if (getters.periodDaysError || getters.personsCountError) {
        return false
      }
      var periodDays = getters.periodDays
      var personsCount = getters.personsCount
      if (periodDays === 0 || personsCount === 0) {
        return false
      }

      // appartement price
      var res = {
        rental: {
          count: periodDays,
          single: getters.basePrice,
          total: periodDays * getters.basePrice
        }
      }

      // linen price
      res.linen = {
        count: personsCount,
        single: rootState.page.calc.linenprice.value,
        total: personsCount * rootState.page.calc.linenprice.value
      }

      // cleaning
      res.cleaning = {
        total: rootState.page.calc.cleaningprice.value
      }

      // add total
      res.sum = {
        total: res.rental.total + res.linen.total + res.cleaning.total
      }
      return res
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {
    initForm (state, appartements) {
      state.result = 0
      fn.each(state.data, (field) => {
        if (!field.value) {
          field.value = field.preset
        }
        field.error = false
      })

      // set appartements as options
      state.data.appartement.options = []
      fn.each(appartements, (appartement, key) => {
        state.data.appartement.options.push({
          key: key,
          label: appartement.title.value,
          personsmax: appartement.calc.personsmax.value,
          baseprice: appartement.calc.baseprice.value,
          link: appartement.link.value
        })
      })
    },
    noInputError (state) {
      state.result = 0
    },
    formSent (state) {
      state.result = 1
      fn.each(state.data, (field) => {
        field.value = field.preset
        field.error = false
      })
    },
    unknownError (state) {
      state.result = 10
    },
    inputError (state) {
      state.result = 11
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {
    validate ({ state, getters, commit }) {
      var error = false
      fn.each(state.data, (field, name) => {
        let valid = Form.typeCheck(field)
        field.error = !valid
        if (field.error) {
          error = true
        }
      })
      switch(getters.periodDaysError) {
        case 1:
          // empty, error already set by typeCheck()
          error = true
          break;
        case 2:
          state.data.arrivalDate.error = true
          error = true
          break;
        case 3:
        case 4:
          state.data.departureDate.error = true
          error = true
          break;
      }
      if (error) {
        commit('inputError')
      } else {
        commit('noInputError')
      }
    },
    submit ({ state, commit, getters, dispatch }) {
      dispatch('validate')
      if (state.result >= 10) {
        return
      }
      var data = {
        __template__: 'request-appartement',
        __from__: state.data.email.value,
        __date__: fn.dateToString(new Date()),
        __time__: fn.timeToString(new Date())
      }
      fn.each(state.data, (field, name) => {
        data[name] = Form.postValue(field, true)
      })
      fn.each(getters.calculation, (field, name) => {
        data[name] = fn.currency(field.total)
      })
      return API.submit('email', data)
        .then(() => {
          commit('formSent')
        })
        .catch((reason) => {
          commit('unknownError')
        })
    }
  }
}

export default requestAppartementStore