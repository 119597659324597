import axios from 'axios'
import Store from '@/services/Store'

const FIELD_TYPE_ARRAY       = 'array'
const FIELD_TYPE_BOOL        = 'bool'
const FIELD_TYPE_DATE        = 'date'
const FIELD_TYPE_EMAIL       = 'email'
const FIELD_TYPE_FILES       = 'files'
const FIELD_TYPE_HTML        = 'html'
const FIELD_TYPE_MULTISELECT = 'multiselect'
const FIELD_TYPE_NUMBER      = 'number'
const FIELD_TYPE_PAGES       = 'pages'
const FIELD_TYPE_SELECT      = 'select'
const FIELD_TYPE_STRING      = 'string'
const FIELD_TYPE_TEL         = 'tel'
const FIELD_TYPE_TIME        = 'time'
const FIELD_TYPE_URL         = 'url'
const FIELD_TYPE_USERS       = 'users'

const apiService = class {

  /*
  |--------------------------------------------------------------------------
  | API Interface
  |--------------------------------------------------------------------------
  */

  /**
   * Get available languages for the site
   * @return {Promise}
   */
  languages () {
    return this._get(fn.toUrl(Store.state.api, 'languages'), {}, false)
  }

  /**
   * Get translation (terms) for given language. Terms are defined in Kirby's
   * language file.
   * @param {string} lang 
   * @return {Promise}
   */
  translations (lang) {
    lang = lang || Store.state.lang
    return this._get(fn.toUrl(Store.state.api, 'translations', lang), {}, false)
  }

  /**
   * Get site content including navigation
   * @param {string} lang 
   * @return {Promise}
   */
  site (lang) {
    lang = lang || Store.state.lang
    return this._get(fn.toUrl(Store.state.api, 'site', lang), {}, true)
  }

  /**
   * Get page/node content
   * @param {string} slug
   * @param {string} lang
   * @return {Promise}
   */
  node (slug, lang) {
    lang = lang || Store.state.lang
    return this._get(fn.toUrl(Store.state.api, 'node', lang, slug), {}, true)
  }

  /**
   * Get page/node children
   * 
   * {integer} page
   * {integer} limit
   * {string} order asc|desc
   * {array} fields [field1, field2]
   * {array} filter [name.eq.value,...], operators: eq, nt, gt, gte, lt, lte
   * {string} search, not implemented, reserved for free-text-search
   * 
   * @param {string} slug
   * @param {object} params
   * @param {string} lang
   * @return {Promise}
   */
  children (slug, request, lang) {
    lang = lang || Store.state.lang
    request = fn.isObject(request) ? request : {}
    var params = {
      page: fn.isInteger(request.page, 1) ? request.page : 1,
      limit: fn.isInteger(request.limit, 1) ? request.limit : 10,
      order: fn.isString(request.order) && request.order === 'desc' ? 'desc' : 'asc',
      fields: fn.isArray(request.fields) ? request.fields : 'all',
      filter: fn.isArray(request.filter) ? request.filter : []
    }
    
    // no cache so far, because params may change and params would need to be
    // added to cache key
    return this._get(fn.toUrl(Store.state.api, 'children', lang, slug), params, false)
  }

  /**
   * Get a static url
   * @param {string} url
   * @return {Promise}
   */
  static (url) {
    return this._getStatic(url)
  }

  /**
   * 
   * @param {string} action the preset in Kirby's email config
   * @param {object} data, the data to post, flat object
   * @param {string} lang
   */
  submit (action, data, lang) {
    lang = lang || Store.state.lang
    return this._post(fn.toUrl(Store.state.api, 'submit', lang, action), data)
  }

  /*
  |--------------------------------------------------------------------------
  | API calls
  |--------------------------------------------------------------------------
  */
  
  /**
   * API get-request
   * @param {string} uri
   * @param {bool} cacheIfEnabled
   * @return {Promise}
   */
  _get (url, params, cacheIfEnabled) {
    info('api request for ' + url)

    // get from cache
    if (cacheIfEnabled && fn.parseBool(process.env.VUE_APP_API_CACHE)) {
      let data = Store.getters['cache/get'](url)
      if (fn.isObject(data)) {
        this._info(data)
        return new Promise((resolve) => {
          resolve({
            status: 200,
            data: data
          })
        })
      }
    }

    // request
    return axios.get(url, {
      params: params || {},
      responseEncoding: 'utf8'
    })
    .then((response) => {
      let data = this._parseResponse(response.data)
      if (cacheIfEnabled && fn.parseBool(process.env.VUE_APP_API_CACHE)) {
        Store.commit('cache/set', {
          url: url,
          data: data
        })
      }
      this._info(data)
      return {
        status: 200,
        data: data
      }
    })
    .catch((error) => {
      return this._getErrorStatus(error, url)
    })
  }

  _post (url, data) {
    info('api post request for ' + url)
    return axios.post(url, data, {
      responseEncoding: 'utf8'
    })
    .then((response) => {
      let data = this._parseResponse(response.data)
      this._info(data)
      return {
        status: 200,
        data: data
      }
    })
    .catch((error) => {
      return this._getErrorStatus(error, url)
    })
  }

  /*
  |--------------------------------------------------------------------------
  | Simple HTTP calls
  |--------------------------------------------------------------------------
  */

  /**
   * @param {string} url
   * @return {Promise}
   */
  _getStatic (url) {
    return axios.get(url)
    .then((response) => {
      return response
    })
    .catch((error) => {
      return this._getErrorStatus(error, url)
    })
  }

  /*
  |--------------------------------------------------------------------------
  | API Helper
  |--------------------------------------------------------------------------
  */

  /**
   * Submethod to determine status code
   * @param {object} error, api response
   * @return {Promise}
   */
  _getErrorStatus (error, url) {
    var status
    var msg = ''

    // error response from server
    if (error.response && error.response.status) {
      status = error.response.status
      if (error.response.data && error.response.data.msg) {
        msg = 'Server said: ' + error.response.data.msg
      }

    // no response
    } else if (error.request) {
      status = 504

    // request error
    } else {
      status = 500
    }
    if (!msg) {
      msg = 'HTTP-error ' + status + ' on calling gateway ' + url
    }
    window.error(status, msg)
    return Promise.reject({ status: status })
  }
  
  /*
  |--------------------------------------------------------------------------
  | Content Helper
  |--------------------------------------------------------------------------
  */

  /**
   * @param object nodes
   * @return object
   */
  _parseResponse (obj) {
    fn.each(obj, (node) => {
      if (fn.isIterable(node)) {
        if (fn.has(node, 'value') && fn.has(node, 'type')) {
          switch(fn.get(node, 'type')) {
            case FIELD_TYPE_ARRAY:
            case FIELD_TYPE_SELECT:
            case FIELD_TYPE_MULTISELECT:
            case FIELD_TYPE_PAGES:
            case FIELD_TYPE_USERS:
              break
            case FIELD_TYPE_FILES:
              this._parseResponse(node)
              break
            case FIELD_TYPE_NUMBER:
              fn.set(node, 'value', parseFloat(node.value))
              break
            case FIELD_TYPE_HTML:
              fn.each(node.value, (block) => {
                let html = block.html // fn.stripslahes($html) also change in HTMLField::value()
                html = this._setRouterLinks(html)
                fn.set(block, 'html', html)
              })
              break
            case FIELD_TYPE_BOOL:
              fn.set(node, 'value', Boolean(node.value))
              break
            case FIELD_TYPE_DATE:
              fn.set(node, 'value', fn.stringToDate(node.value))
              break
            case FIELD_TYPE_TIME:
              fn.set(node, 'value', fn.stringToTime(node.value))
              break
            case FIELD_TYPE_URL:
            case FIELD_TYPE_EMAIL:
            case FIELD_TYPE_TEL:
            case FIELD_TYPE_STRING:
              break
          }
        } else {
          this._parseResponse(node)
        }
      }
    })
    return obj
  }

  /**
   * replace intern links with <router-link> to let the router handle it
   * @param {string} html 
   */
  _setRouterLinks (html) {
    let regex = /<a.+?data-link-intern.*?>(.*?)<\/a>/gi
    html = html.replace(regex, (match, text) => {

      // don't extract in first regex, because it's not possible to know,
      // if href is before or after data-link-extern
      let regex = /href=["|'](.*?)["|']/i
      let href = regex.exec(match)
      let url = fn.isArray(href) ? href[1] : '/'
      return '<router-link to="' + url + '" data-link-intern>' + text + '</router-link>'
    })
    return html
  }

  /**
   * Logging depending on settings in /config/logger.json
   * @param mixed obj
   */
  _info (obj) {
    if (fn.parseBool(process.env.VUE_APP_LOG_API)) {
      info(obj)
    }
  }
}

export default new apiService()