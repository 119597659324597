import Form from '@/services/Form'
import API from '@/services/API'

const requestEventStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    result: 0, // 0 = init, 1 = sent, 10 = unknown error, 11 = input error
    data: {
      company: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      salutation: {
        type: 'select',
        value: null,
        preset: 'female',
        error: false,
        required: true,
        options: [{
          key: 'female',
          label: 'Frau'
        },{
          key: 'male',
          label: 'Herr'
        }]
      },
      prename: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      surname: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      street: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      zip: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      town: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      phone: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      email: {
        type: 'email',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      eventTitle: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      eventDescription: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      eventType: {
        type: 'select',
        value: null,
        preset: 'single',
        error: false,
        required: true,
        options: [{
          key: 'single',
          label: 'eintägig / einmalig'
        },{
          key: 'course',
          label: 'regelmäßig / mehrmalig (Kursus)'
        },{
          key: 'workshop',
          label: 'mehrtägig (Seminar, Workshop)'
        }]
      },
      eventPublic: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      eventDatetime: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: true
      },
      participantsCount: {
        type: 'integer',
        value: null,
        preset: null,
        min: 0,
        max: 100,
        error: false,
        required: true
      },
      overnightCount: {
        type: 'integer',
        value: null,
        preset: null,
        min: 0,
        error: false,
        required: true
      },
      room1: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      room2: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      room3: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      roomUnknown: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      equipmentBeamer: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      equipmentScreen: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      equipmentPiano: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      equipmentFlipchart: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      equipmentWlan: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: false
      },
      catering: {
        type: 'select',
        value: null,
        preset: 'none',
        error: false,
        required: true,
        options: [{
          key: 'none',
          label: 'keine Verpflegung'
        },{
          key: 'self',
          label: 'Selbstverpflegung in der Küche des Hauses'
        },{
          key: 'breakfast',
          label: 'Frühstück'
        },{
          key: 'half',
          label: 'Halbpension'
        },{
          key: 'full',
          label: 'Vollpension'
        }]
      },
      message: {
        type: 'text',
        value: null,
        preset: null,
        error: false,
        required: false
      },
      conditions: {
        type: 'bool',
        value: false,
        preset: null,
        error: false,
        required: true
      }
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {},

  /*
  |--------------------------------------------------------------------------
  | Mutations
  |--------------------------------------------------------------------------
  */

  mutations: {
    initForm (state) {
      state.result = 0
      fn.each(state.data, (field) => {
        if (!field.value) {
          field.value = field.preset
        }
        field.error = false
      })
    },
    noInputError (state) {
      state.result = 0
    },
    formSent (state) {
      state.result = 1
      fn.each(state.data, (field) => {
        field.value = field.preset
        field.error = false
      })
    },
    unknownError (state) {
      state.result = 10
    },
    inputError (state) {
      state.result = 11
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {
    validate ({ state, getters, commit }) {
      var error = false
      fn.each(state.data, (field, name) => {
        var required = field.required
        if (name === 'overnightCount' && state.data.eventType.value !== 'workshop') {
          field.required = false
        }
        field.error = !Form.typeCheck(field)
        field.required = required
        if (field.error) {
          error = true
        }
      })
      if (error) {
        commit('inputError')
      } else {
        commit('noInputError')
      }
    },
    submit ({ state, commit, getters, dispatch }) {
      dispatch('validate')
      if (state.result >= 10) {
        return
      }
      var data = {
        __template__: 'request-event',
        __from__: state.data.email.value,
        __date__: fn.dateToString(new Date()),
        __time__: fn.timeToString(new Date())
      }
      fn.each(state.data, (field, name) => {
        data[name] = Form.postValue(field, true)
      })

      if (state.data.eventType.value !== 'workshop') {
        state.data.overnightCount.value = null
      }

      return API.submit('email', data)
        .then(() => {
          commit('formSent')
        })
        .catch((reason) => {
          commit('unknownError')
        })
    }
  }
}

export default requestEventStore