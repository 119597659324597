import API from '@/services/API'
import Store from '@/services/Store'
import Scroll from '@/services/Scroll'
import EventManager from '@/plugins/EventManager'
import imagesConfig from '@/config/images.json'
import { breakpoints } from '@/config/style.json'

const domService = class {
  constructor () {
    this.croptypes = [
      'top-left',
      'top',
      'top-right',
      'left',
      'right',
      'bottom-left',
      'bottom',
      'bottom-right'
    ]
    this.quality = {
      'min': 50,
      'max': 95,
      'default': 90
    }
    this.viewBox = {}
  }

  /*
  |--------------------------------------------------------------------------
  | SVG-Sprite
  |--------------------------------------------------------------------------
  */

  loadSprite () {
    return API.static(process.env.BASE_URL + 'svg/sprite.svg')
    .then(response => {
      var node = document.getElementById('sprite')
      if (node) {
        node.innerHTML = response.data

        // Build a map with viewBox attributes for each svg
        var svgs = node.querySelectorAll('symbol')
        fn.each(svgs, (svg) => {
          let name = svg.getAttribute('id')
          this.viewBox[name] = svg.getAttribute('viewBox')
        })
      }
    })
  }

  getViewBox (name) {
    if (fn.has(this.viewBox, name)) {
      return this.viewBox[name]
    }
    return '0 0 300 300'
  }

  /*
  |--------------------------------------------------------------------------
  | Page-Meta
  |--------------------------------------------------------------------------
  */

  setPageMeta () {
    if (Store.getters.pageError) {
      this.setPageMetaError(Store.state.status.page)
    } else {
      this.setPageMetaOK()
    }
  }

  setPageMetaOK () {
    if (!fn.has(Store.state.site, 'meta')) {
      throw({ status: 1001, msg: 'site-data for default language not set'})
    }
    if (!fn.has(Store.state.page, 'meta') || !fn.has(Store.state.page, 'head')) {
      throw({ status: 1002, msg: 'page-data for default language not set'})
    }

    var meta = []

    var title = this._getTitle(
      Store.state.site.meta.title.value,
      Store.state.page.meta.title.value,
      Store.state.page.head.title,
      Store.state.site.meta.titleorder.value
    )
    meta.push({
      'tag': 'title',
      'content': title
    })
    meta.push({
      'property': 'og:title',
      'content': title
    })

    var description = this._getContent(
      fn.htmlToText(Store.state.site.meta.description.value, true),
      fn.htmlToText(Store.state.page.meta.description.value, true)
    )
    meta.push({
      'name': 'description',
      'content': description
    })
    meta.push({
      'property': 'og:description',
      'content': description
    })
    meta.push({
      'name': 'twitter:card',
      'content': description
    })


    meta.push({
      'name': 'keywords',
      'content': this._getContent(
        Store.state.site.meta.keywords.value.join(', '),
        Store.state.page.meta.keywords.value.join(', ')
      )
    })
    meta.push({
      'name': 'author',
      'content': this._getContent(
        Store.state.site.meta.author.value
      )
    })
    meta.push({
      'property': 'og:url',
      'content': fn.currentUrl()
    })
    meta.push({
      'property': 'og:type',
      'content': this._getContent(Store.state.page.meta.type.value)
    })
    meta.push({
      'property': 'og:locale',
      'content': this._getContent(fn.upper(Store.state.lang) + '_' + fn.lower(Store.state.lang))
    })

    var image = this._getImage()
    meta.push({
      'property': 'og:image',
      'content': fn.has(image, 'url') ? image.url : null
    })
    meta.push({
      'property': 'og:image:width',
      'content': fn.has(image, 'url') ? fn.toString(image.width) : null
    })
    meta.push({
      'property': 'og:image:height',
      'content': fn.has(image, 'url') ? fn.toString(image.height) : null
    })
    this._domSetMeta(meta)
  }

  setPageMetaError (errno) {
    var meta = []
    meta.push({
      'tag': 'title',
      'content': 'Error ' + errno
    })
    this._domSetMeta(meta)
  }

  /*
  |--------------------------------------------------------------------------
  | Helper
  |--------------------------------------------------------------------------
  */

  _getTitle (site, page, title, order) {
    return fn.escape(
      order.replace('_', ' | ')
      .replace('site', site)
      .replace('page', fn.isString(page) ? page : title)
    )
  }

  _getContent (val1, val2) {
    if (fn.isString(val2)) {
      return fn.escape(val2, true)
    }
    if (fn.isString(val1)) {
      return fn.escape(val1, true)
    }
    return null
  }

  _getImage () {
    var config = []
    if (
      fn.has(Store.state.site.api, 'seoImage') &&
      fn.has(Store.state.site.api.metaImage, 'width') &&
      fn.has(Store.state.site.api.metaImage, 'height') && 
      fn.has(Store.state.site.api.metaImage, 'crop')) {
        config = [
          parseInt(Store.state.site.api.metaImage.width),
          parseInt(Store.state.site.api.metaImage.height),
          Store.state.site.api.metaImage.crop
        ]
    } else {
      config = [1200, 630, true]
    }
    if (fn.isObject(Store.state.page.meta.image.value)) {
      return this.getImage(Store.state.page.meta.image.value, config, false, true)
    }
    return this.getImage(Store.state.site.meta.image.value, config, false, true)
  }

  _domSetMeta (meta) {
    fn.each(meta, (obj) => {
      if (fn.has(obj, 'tag')) {
        let node = document.head.querySelectorAll(obj.tag)
        if (node.length === 1 && node[0].innerHTML !== obj.content) {
          node[0].innerHTML = obj.content
        }
      } else {
        let node = []
        if (fn.has(obj, 'name')) {
          node = document.head.querySelectorAll('meta[name="' + obj.name + '"]')
        } else if (fn.has(obj, 'property')) {
          node = document.head.querySelectorAll('meta[property="' + obj.property + '"]')
        }
        if (node.length === 1 && node[0].getAttribute('content') !== obj.content) {
          node[0].setAttribute('content', obj.content)
        }
      }
    })
  }

  /*
  |--------------------------------------------------------------------------
  | Images
  |--------------------------------------------------------------------------
  */

  /**
   * Get image url with all image manipulations coded in string
   * like it is processed by API
   * 
   * @param {object} image, object like given from api (value-node)
   * @param {array} config, format below, can also be path so config is
   *    taken from images.json (like "sections.banner.md")
   * @param {boolean} retina, for retina display = double resolution
   * @return {object}
   *
   * config: [
   *   0 {integer || breakpoint || 'screen' || null} max width,
   *   1 {integer || breakpoint || 'screen' || null} max height,
   *   2 {boolean || string} crop, false = no cropping, true = 'center'
   *      or one of these values:
   *      top-left, top, top-right, left, right, bottom-left, bottom, bottom-right
   *   3 {integer} quality, between 50 and 100, default 90
   *   4 {integer || false} blur-factor, default false
   *   5 {boolean} convert to black and white, default false
   * ]
   */
  getImage (image, config, retina) {
    var url, crop, cropType, quality, blur, bw, res

    // get config-array from presets
    if (fn.isString(config)) {
      config = fn.path(imagesConfig, config, true)
      if (!config) {
        config = fn.path(imagesConfig, 'default')
      }
    }
    if (!fn.isObject(image) || !fn.isArray(config)) {
      return
    }

    // get width and height
    // width and height can be set to breakpoint-name, which means, the image
    // has the maxiumum dimensions for this breakpoint
    if (fn.isString(config[0]) && fn.has(breakpoints, config[0])) {
      config[0] = breakpoints[config[0]]
    }
    if (fn.isString(config[1]) && fn.has(breakpoints, config[1])) {
      config[1] = breakpoints[config[1]]
    }

    // get crop option
    // Cropping itselfis always true in API! An uncropped images is generated by giving
    // dimensions that have the same object ratio as the original. Here we compute
    // crop:bool for the dimenension-function and
    // croptype for any special crop
    crop = false
    cropType = null
    if (fn.has(config, 2)) {
      if (fn.inArray(config[2], this.croptypes)) {
        crop = true
        cropType = config[2]
      } else if (fn.isTrue(config[2])) {
        crop = true
      }
    }

    // get quality option
    quality = false
    if (
      fn.has(config, 3) &&
      fn.isInteger(config[3]) &&
      config[3] >= this.quality.min &&
      config[3] <= this.quality.max) {
        quality = config[3]
    } else {
      quality = this.quality.default
    }

    // get blur option
    blur = false
    if (fn.has(config, 4) && fn.isInteger(config[4]) && config[4] > 0) {
      blur = config[4]
    }

    // get black and white option
    bw = false
    if (fn.has(config, 5) && fn.isTrue(config[5])) {
      bw = true
    }

    // calculate dimensions
    res = this._calculateDimensions({
        width: image.width,
        height: image.height
      }, {
        width: config[0],
        height: config[1]
      },
      crop,
      retina
    )

    // normalize extension like kirby does in
    // kirby/src/CMS/Filename.php::sanitizeExtension()
    let extension = image.extension.toLowerCase().replace(/jpeg/, 'jpg')

    // build url
    // filename-(width)x(height)[-crop-(option)][-blur(integer)][-bw][-q(integer)].extension
    url = []
    url.push(image.dirname + image.filename)
    url.push(res.width + 'x' + res.height)
    if (cropType) {
      url.push('crop-' + cropType)
    }
    if (blur) {
      url.push('blur' + blur)
    }
    if (bw) {
      url.push('bw')
    }
    if (quality) {
      url.push('q' + quality)
    }
    res.url = url.join('-') + '.' + extension
    return res
  }

  /**
   * Get Image dimensions
   * @param {object} orig, width and height of original
   * @param {mixed} max, maximum width and height
   * @param {boolean} retina, for retina displays
   * @param {boolean} crop, crop image
   * @return {object}
   */
  _calculateDimensions (orig, max, crop, retina) {
    var res = {
      width: null,
      height: null,
      ratio: fn.round(orig.width / orig.height, 4)
    }

    // keep ratio, limit height to maxHeight
    if (max.width === null) {
      res.width = fn.round(max.height * res.ratio, 0)
      res.height = max.height

    // keep ratio, limit width to max.width
    } else if (max.height === null) {
      res.width = max.width
      res.height = fn.round(max.width / res.ratio, 0)

    // crop to fit in max.width and max.height
    } else if (crop) {
      res.width = max.width
      res.height = max.height

    // keep ratio, fit either max.width or max.height
    } else {
      res.width = fn.round(max.height * res.ratio, 0)
      if (res.width <= max.width) {
        res.height = max.height
      } else {
        res.width = max.width
        res.height = fn.round(max.width / res.ratio, 0)
      }
    }

    // double resolution for retina displays
    if (retina) {
      res.width = res.width * 2
      res.height = res.height * 2
    }

    // correct the dimensions to not be bigger than original
    // bigger 1 means dimension is bigger than original
    if ((res.width / orig.width) > 1 || (res.height / orig.height) > 1) {

      // take orig.width and calculate height
      if ((res.width / orig.width) >= (res.height / orig.height)) {
        if (max.height === null) {
          res.height = orig.height
        } else {
          res.height = fn.round(orig.width * res.height / res.width, 0, 'down')
        }
        res.width = orig.width

      // take orig.height and calculate width
      } else {
        if (max.width === null) {
          res.width = orig.width
        } else {
          res.width = fn.round(orig.height * res.width / res.height, 0, 'down')
        }
        res.height = orig.height
      }
    }
    return res
  }

  /*
  |--------------------------------------------------------------------------
  | URL, Routes
  |--------------------------------------------------------------------------
  */

  getActiveClasses (route, uri, activeClass, exactClass) {
    activeClass = activeClass || 'is-active'
    exactClass = exactClass || 'is-exact-active'
    if (this.isExactUri(route, uri)) {
      return [ activeClass, exactClass ]
    } else if (this.isActiveUri(route, uri)) {
      return [ activeClass ]
    }
    return []
  }

  isExactUri (route, uri) {
    return route === uri
  }

  isActiveUri (route, uri) {
    return route.substr(0, uri.length + 1) === uri + '/'
  }


  /**
   * This is obsolet, when link is converted to ONE Kirby field with all
   * properties in one object link in navigation links.
   * Now the situation is, that either the
   * navigation link is given or seperate fields.
   * 
   * also any children have link information in head node, this must be
   * considered in final solution also
   */
  linksHelperTemp (links) {
    var res = []
    fn.each(links, (link) => {
      res.push(this.linkHelperTemp(link))
    })
    return res
  }
  
  linkHelperTemp (link) {
    var res = {}

    // translate to navigation link structure
    // link-group-fields
    // fields given: type, caption, page/file/email/extern/scroll
    if (fn.has(link, 'type') && fn.has(link.type, 'value')) {
      res.type = link.type.value
      switch (res.type) {
        case 'page':
          res.uri = link.page.value.uri
          if (fn.has(link.page.value, 'redirect')) {
            res.redirect = link.page.value.redirect
          }
          res.id = link.page.value.id
          break;
        case 'extern':
          res.url = link.extern.value.url
          break
        case 'email':
          res.url = link.email.value.url
          break
        case 'file':
          res.url = link.file.value.dirname + link.file.value.basename
          break
        case 'scroll':
          res.anchor = link.scroll.value
          break
      }
      if (fn.has(link, 'caption') && fn.isString(link.caption.value)) {
        res.title = link.caption.value
      } else {
        res.title = ''
      }
      if (fn.has(link, 'cssclass') && fn.isString(link.cssclass.value)) {
        res.cssclass = link.cssclass.value
      } else {
        res.cssclass = ''
      }
    }

    // normal fields
    else if (fn.has(link, 'type') && fn.has(link, 'value')) {
      switch(link.type) {
        case 'email':
          res.type = 'email'
          res.title = link.value
          res.url = 'mailto:' + link.value
          break;
        case 'url':
          res.type = 'extern'
          res.title = link.value
          res.url = link.value
          break;
      }
    }
    else if (fn.has(link, 'type') && fn.has(link, 'content')) {
      switch(link.type) {
        case 'file':
          res.type = 'file'
          res.title = link.content.title.value
          res.url = link.dirname + link.basename          
          break;
      }
    }
    else {
      res = link
    }
    return res
  }

  /*
  |--------------------------------------------------------------------------
  | Elements, Positions, Dimensions
  |--------------------------------------------------------------------------
  */

  getWindowWidth () {
    return window.innerWidth
  }

  getWindowHeight () {
    return window.innerHeight
  }

  getDocumentScrollTop () {
    return window.scrollY
  }

  // inclucing invisible part
  getDocumentHeight () {
    return Math.max(
      document.documentElement['clientHeight'],
      document.body['scrollHeight'],
      document.documentElement['scrollHeight'],
      document.body['offsetHeight'],
      document.documentElement['offsetHeight']
    )
  }
  
  // inclucing invisible part
  getDocumentWidth () {
    return Math.max(
      document.documentElement['clientWidth'],
      document.body['scrollWidth'],
      document.documentElement['scrollWidth'],
      document.body['offsetWidth'],
      document.documentElement['offsetWidth']
    )
  }

  /**
   * get dimensions of a dom element, like defined by this.$refs.myNode
   * @param {DOMelement} node
   */
  getDimensions (node) {
    var res = {}
    res.width   = this.getWidth(node)
    res.height  = this.getHeight(node)
    res.top     = node.offsetTop
    res.left    = node.offsetLeft
    res.bottom  = res.top + res.height
    res.right   = res.left + res.width
    return res
  }

  getWidth (node) {
    if (node && node instanceof HTMLElement) {
      return parseInt(getComputedStyle(node).width.split('px')[0])
    }
    return 0
  }

  getHeight (node) {
    if (node && node instanceof HTMLElement) {
      return parseInt(getComputedStyle(node).height.split('px')[0])
    }
    return 0
  }

  /**
   * @param {DOMelement} node
   * @param {String} prop
   * @param {String} value
   */
  setStyle (node, prop, value) {
    if (value === undefined) {
      node.style[prop] = null
    } else {
      node.style[prop] = value
    }
  }

  getBreakpoint () {
    var res
    fn.each(breakpoints, (value, key) => {
      if (window.innerWidth <= value) {
        res = key
      }
    })
    return res
  }

  /*
  |--------------------------------------------------------------------------
  | Window
  |--------------------------------------------------------------------------
  */

  fixBody () {
    var top = this.getDocumentScrollTop()
    var style = document.body.style
    style.position = 'fixed'
    style.width = '100%'
    style.top = '-' + top + 'px'
  }

  releaseBody () {
    var style = document.body.style
    var top = style.top
    style.position = ''
    style.width = ''
    style.top = ''

    // don't trigger events on scrolling here
    EventManager.$windowEvents(false)
    Scroll.native(
      parseInt(top || '0') * -1, {
        callback: () => {
          EventManager.$windowEvents(true)
        }
      }
    )
  }
}

export default new domService()