import Vue from 'vue'
import Vuex from 'vuex'

import rootStore from '@/stores/root'
import cookieStore from '@/stores/cookie'
import cacheStore from '@/stores/cache'
import userStore from '@/stores/user'
import mapboxStore from '@/stores/mapbox'
import requestGroupStore from '@/stores/requestGroup'
import requestEventStore from '@/stores/requestEvent'
import requestAppartementStore from '@/stores/requestAppartement'

Vue.use(Vuex)

const StoreService = new Vuex.Store(rootStore)

StoreService.registerModule('cookie', cookieStore)
StoreService.registerModule('cache', cacheStore)
StoreService.registerModule('user', userStore)
StoreService.registerModule('mapbox', mapboxStore)
StoreService.registerModule('requestGroup', requestGroupStore)
StoreService.registerModule('requestEvent', requestEventStore)
StoreService.registerModule('requestAppartement', requestAppartementStore)

export default StoreService