import Vue from 'vue'

const cookieStore = {
  namespaced: true,

  /*
  |--------------------------------------------------------------------------
  | State
  |--------------------------------------------------------------------------
  */
  state: {
    name: '_cs',
    valid: 30, // days
    consent: 0, // 0 = unknown, show dialog, 1 = permission, -1 = no permission
    data: {}
  },

  /*
  |--------------------------------------------------------------------------
  | Getters
  |--------------------------------------------------------------------------
  */

  getters: {

    /**
     * returns null, boolean, number or string
     */
    get: (state) => (key) => {
      if (fn.has(state.data, key)) {
        return state.data[key]
      }
    },

    /**
     * Length indicates if a cookie is required at all. If length = 0 no
     * cookie dialog will be shown. At first time, when a module sets or
     * inits a cookie value, length will be > 0 and the cookie message
     * appears.
     */
    length: (state) => {
      return Object.keys(state.data).length
    },

    /**
     */
    showConsentDialog: (state, getters) => {
      return state.consent === 0 && getters.length > 0
    },

    /**
     */
    hasConsent: (state) => {
      return state.consent === 1
    }
  },

  /*
  |--------------------------------------------------------------------------
  | Actions
  |--------------------------------------------------------------------------
  */

  actions: {

    /**
     * Sets a value only if property is not existing.
     * Use this method when a module needs a cookie property. Because the
     * property is added to data the cookie message will appear to request
     * user consent.
     */
    init ({ state, dispatch}, params) {
      fn.each(params, (value, key) => {
        if (!fn.has(state.data, key)) {
          let prop = {}
          prop[key] = value
          dispatch('set', prop)
        }
      })
    },

    /**
     * Sets a value and stores it in cookie, if consent is given.
     * No reactivity here on state.data properties! This is not needed,
     * because cookie values are not for constant use in application,
     * it's a simple store away and read it on next site visit.
     * 
     * Only strings, numbers and boolean values accepted
     */
    set ({ state, dispatch }, params) {
      if (!fn.isObject(params)) {
        return
      }
      fn.each(params, (value, key) => {
        state.data[key] = value
      })
      dispatch('setCookie')
    },

    /**
     */
    setConsent ({ state, dispatch }, param) {
      state.consent = fn.isTrue(param) ? 1 : -1
      dispatch('setCookie')
    },

    /**
     */
    setCookie({ state }) {
      if (state.consent !== 1) {
        return
      }
      var expires = new Date()
      expires.setDate(expires.getDate() + state.valid)
      var cookie = [
        state.name, '=', JSON.stringify(state.data),
        '; path=/',
        '; expires=', expires.toUTCString(),
        ';'
      ].join('')
      document.cookie = cookie
    }
  }
}

var res = document.cookie.match(new RegExp(cookieStore.state.name + '=([^;]+)'))
if (res) {
  cookieStore.state.data = JSON.parse(res[1])
  cookieStore.state.consent = 1
}

export default cookieStore