const VueExtensions = class {
  install (Vue, options) {
    Vue.filter('currency', function (value) {
      return fn.currency(value)
    })
    Vue.filter('date', function (dateObj) {
      return fn.dateToString(dateObj, true, 'short')
    })
    Vue.filter('date_short', function (dateObj) {
      return fn.dateToString(dateObj, true, 'short')
    })
    Vue.filter('date_weekday', function (dateObj) {
      return fn.dateToString(dateObj, true, 'weekday')
    })
    Vue.filter('date_long', function (dateObj) {
      return fn.dateToString(dateObj, true, 'long')
    }) 
    Vue.filter('time', function (dateObj) {
      return fn.timeToString(dateObj, true, 'short')
    })
    Vue.filter('time_short', function (dateObj) {
      return fn.timeToString(dateObj, true, 'short')
    })
    Vue.filter('time_long', function (dateObj) {
      return fn.timeToString(dateObj, true, 'long')
    })
  }
}

export default new VueExtensions()