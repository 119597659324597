require('@/debug/Logger')
require('@/services/Functions')
import Vue from 'vue'
import Vue2TouchEvents from 'vue2-touch-events'
import VuePlyr from 'vue-plyr'
import EventManager from '@/plugins/EventManager'
import VueExtensions from '@/plugins/VueExtensions'
import Analytics from '@/plugins/Matomo'
import Router from '@/services/Router'
import Store from '@/services/Store'
import Loader from '@/services/Loader'
import DOM from '@/services/DOM'
import Locale from '@/services/Locale'
import { breakpoints } from '@/config/style.json'
import matomoConfig from '@/config/matomo.json'

import 'vue-plyr/dist/vue-plyr.css'

/*
|--------------------------------------------------------------------------
| Configuration
|--------------------------------------------------------------------------
*/

Loader.loadGlobalElements()
Vue.use(Vue2TouchEvents)
Vue.use(VuePlyr)
Vue.use(EventManager, {
  breakpoints: breakpoints
})
Vue.use(VueExtensions)
Vue.use(Analytics, matomoConfig)
Vue.config.productionTip = false
Store.commit('setEnviromentVars')

/*
|--------------------------------------------------------------------------
| Mount application
|--------------------------------------------------------------------------
*/
new Promise((resolve) => {
  info('loading app from api')
  resolve()
})
.then(() => {
  return Store.dispatch('loadLanguages')
})
.then(() => {
  return Promise.all([
    DOM.loadSprite(),
    Store.dispatch('loadSite')
  ])
})
.then(() => {
  new Vue({
    router : Router.component(),
    store: Store,
    i18n: Locale.component(),
    template: '<router-view></router-view>',
    mounted () {
      info('mounted')
    }
  }).$mount('#page')
})
.catch((reason) => {
  error(reason.status, reason.msg)
})
